<template>
<div>
	<creatsuccess :msg="showtype" @recovery="recovery">
		<div class="suppierDetail">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>基本信息</span>
					</div>
					<div class="content">

						<el-form-item label="活动名称：" prop="name">
							<el-input v-model="ruleForm.name" style="width: 200px;" :disabled="activeState==3" placeholder="最多输入20个字"
							maxlength="20"></el-input>
						</el-form-item>

						<el-form-item label="活动时间：" required>

							<el-form-item prop="activeStartTime" class="el-form-date-pick">
								<el-date-picker v-model="ruleForm.activeStartTime" :disabled="activeState==3||activeState==2" type="datetime"
								placeholder="开始时间" :picker-options='maxOptions'>
								</el-date-picker>
							</el-form-item>
							<span style="margin:0 10px;">~</span>
							<el-form-item prop="activeEndTime" class="el-form-date-pick">
								<el-date-picker v-model="ruleForm.activeEndTime" :disabled="activeState==3" type="datetime" placeholder="结束时间"
								:picker-options='minOptions'>
								</el-date-picker>
							</el-form-item>

						</el-form-item>

						<el-form-item label="打包规则：" required>

							<el-form-item prop="packRuleMoney" class="el-form-date-pick">
								<el-input style="margin-right:10px;width: 80px;" :disabled="activeState==3" @blur="checkPrice($event)" v-model="ruleForm.packRuleMoney"></el-input>元
								<!-- <el-input 
								style="margin-right:10px;width: 80px;" 
								:disabled="activeState==3" 
								onkeyup="var p2 = parseFloat(Math.floor(value * 100) / 100) ;value = p2>=0?(/\.0?$/.test(value)?value:p2.replace(/0$/,'').replace(/\.0$/,'')):''" 
								onblur="value = value.replace(/\.0*$/,'')"
								v-model="ruleForm.packRuleMoney"></el-input>元 -->

								<!-- parseFloat(value).toFixed(2)
								Number(15.7784514000.toString().match(/^\d+(?:\.\d{0,2})?/)) -->
								<!-- .match(/\d+(\.\d{0,2})?/) -->
							</el-form-item>

							<el-form-item prop="packRulePiece" class="el-form-date-pick">
								<el-input onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
								onblur="this.v();if (value==0||value=='-'){value =''}" style="margin:0 10px;width: 80px;" :disabled="activeState==3"
								v-model="ruleForm.packRulePiece"></el-input>件
							</el-form-item>

						</el-form-item>

						<el-form-item label="活动标签：" style="margin-bottom: 10px !important;">
							<el-input v-model="ruleForm.ActivityFlag" :disabled="activeState==3" placeholder="最多输入10个字" maxlength="10" style="width:200px"></el-input>
							<active-tag></active-tag>
						</el-form-item>

						
						<el-form-item label="优惠券叠加：" style="margin-bottom: 10px !important;">
							<el-radio-group v-model="ruleForm.IsSuperpositionCoupon" :disabled="activeState==3">
								<el-radio :label="true">叠加</el-radio>
								<el-radio :label="false">不叠加</el-radio>
							</el-radio-group>
							<div style="font-size:12px;color:#999999">开启优惠券叠加后，本场活动商品可同时享有打包一口价优惠、优惠券优惠</div>
						</el-form-item>

					</div>
				</el-card>

				<el-card class="box-card" style="margin-top:30px;margin-bottom:100px;">
					<div slot="header" class="clearfix">
						<span>活动信息</span>
					</div>
					<div class="content">
						<span class="is-error-button" style="color:#409EFF;cursor: pointer;font-size: 14px;margin-right:10px;" v-if="activeState!=3" @click="handleSelectProShow">选择活动商品</span>
						<el-input v-model="productSearchKey" placeholder="搜索已选商品名称、编码" style="width:250px;"  @keyup.enter.native="handleFilterSelectes"> <el-button slot="append" icon="el-icon-search" @click='handleFilterSelectes'></el-button></el-input>
						<el-form-item label-width="0px">
							<el-table border :data="paginationList" style="width: 100%;margin-top: 20px;">
								<el-table-column label="商品" width="300px">
									<template slot-scope="scope">
										<div class="dialog-name-content">
											<img :src='imgUrl+scope.row.ImgUrl' />
											<div class="right">
												<div class="name">
													<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>

												</div>
												<span v-if="!scope.row.IsOpen">已下架</span>
												<span v-if="scope.row.Stock<1 ">已售罄</span>
											</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="ProductPrice" label="价格"></el-table-column>
								<el-table-column prop="Stock" label="库存"></el-table-column>
								<el-table-column label="操作" v-if="activeState!=3">
									<template slot-scope="scope">
										<!-- <span style="color:#C0C4CC;" v-if="activeState==3">删除</span> -->
										<!-- <span style="color:#F56C6C;;" v-else @click="deleteSortPro(scope.row.Id)">删除</span> -->
										<el-button style="color:#C0C4CC;" v-if="activeState==3" type="text" size="small">删除</el-button>
										<el-button style="color:#F56C6C;" v-else @click="deleteSortPro(scope.row,scope.$index)" type="text" size="small">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
							@current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10, 20]" :page-size="page.size"
							layout="total, sizes, prev, pager, next, jumper" :total="page.total">
							</el-pagination>
						</el-form-item>
					</div>
				</el-card>
			</el-form>
			<!-- position:fixed;bottom:0;right:0;border-top:1px solid #ddd;background-color:#fff;width:100%; -->
			<div class="footer">
				<el-button style="width:240px;" @click="suppilerCansel">取消</el-button>
				<el-button style="width:240px;" type="primary" v-if="activeState!=3" @click="saveSupiler('ruleForm')" :loading="loading">保存</el-button>
			</div>
			<!-- dialog -->
			<!-- 选择商品 -->
			<el-dialog title="选择商品" :visible.sync="selectProShow" width="1300px" class="dialog">
				<select-produce :visible.sync="selectProShow" v-if="selectProShow" api="editProduct" :params="['Keywords', 'ProductType', 'ProductGroup', 'ProductBrand', 'IsSelectable','ActivityId']"
				:selectedData="tableProList" :ActivityFullId="activityId" :couponData="timeData" @getSelectList="getSelectList">
				</select-produce>
			</el-dialog>

			<!-- :params="['Keywords', 'ProductType', 'ProductGroup', 'ProductBrand', 'IsSelectable']" -->

			<!-- 冲突商品列表 -->
			<el-dialog :visible.sync="conflictVisible" title="以下商品与其它活动冲突,请重新设置" center>
				<el-table :data="conflictList" style="width: 100%;max-height:450px;overflow: auto;">
					<el-table-column label="商品" width="300px">
						<template slot-scope="scope">
							<div class="dialog-name-content">
								<img :src='imgUrl+scope.row.ImgUrl' />
								<div class="right">
									<div class="name">
										{{scope.row.ProductName}}
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="冲突信息">
						<template slot-scope="scope">
							<span>
								已参加{{scope.row.ActivityTypeDisplay}}:{{scope.row.Name}}
							</span>
						</template>
					</el-table-column>
				</el-table>
			</el-dialog>
		</div>
	</creatsuccess>
</div>
</template>

<script>
	import config from '@/config/index'
	import activeTag from '../components/activeTag'
	import selectProduce from '@/components/SelectMulProduce/SelectProduceTime';
	import creatsuccess from "@/components/creatsuccess/creatsuccess.vue"

	import {
		editSave,
		editInit
	} from "@/api/goods.js";

	export default {

		components: {
			selectProduce,
			activeTag,
			creatsuccess
		},
		data() {
			var checkName = (rule, value, callback) =>{
				if(!value){
					return callback(new Error('请输入活动名称'));
				}else if(!value.trim() ){
					return callback(new Error('请输入活动名称'));
				}else{
					return callback()
				}
			};
			var checkPackRulePiece = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入打包规则件数'))
				} else {
					if (value < 2) {
						return callback(new Error(`打包件数最少两件，整数`))
					} else {
						return callback()
					}
				}
			}
			return {
				imgUrl: config.IMG_BASE,
				loading: false,
				ruleForm: {
					name: '',
					activeStartTime: '',
					activeEndTime: '',
					packRuleMoney: '',
					packRulePiece: '',
					ActivityFlag: '',
					IsSuperpositionCoupon:false,
				},
				currentCount: '',
				rules: {
					name: [{
						required: true,
						// message: '请输入活动名称',
						trigger: 'blur',
						validator:checkName
					}],
					activeStartTime: [{
						type: 'date',
						required: true,
						message: '请选择开始日期',
						trigger: 'blur'
					}],
					activeEndTime: [{
						type: 'date',
						required: true,
						message: '请选择结束日期',
						trigger: 'blur'
					}],
					packRuleMoney: [{
						required: true,
						message: '请输入打包规则价格',
						trigger: 'blur'
					}],
					packRulePiece: [{
						trigger: 'blur',
						validator: checkPackRulePiece
					}],
				},
				// dialog
				selectProShow: false,
				searchKey: '',
				type: null,
				typeOptions: [{
						value: '类型一',
						lable: 1
					},
					{
						value: '类型二',
						lable: 2
					}
				],
				group: null,
				groupOptions: [{
						value: '类型一',
						lable: 1
					},
					{
						value: '类型二',
						lable: 2
					}
				],
				brand: null,
				brandOptions: [{
						value: '类型一',
						lable: 1
					},
					{
						value: '类型二',
						lable: 2
					}
				],
				page: {
					total: 0,
					current: 1,
					size: 5
				},
				tableProList: [],
				selectedFilterList:[],
				paginationList: [],
				productSearchKey:'',
				multipleSelection: [],
				selectCount: 5,
				checked: false,
				checkedShopPro: false,

				isCopy: false,
				onlyLook: false,
				activityId: 0,

				conflictVisible: false, //是否显示冲突tank
				conflictList: [], //冲突信息列表
				activeState: 1,
				timeData: {
					StartTime: '',
					EndTime: ''
				},
				showtype:{
					issuccess:false,
					id:0,
					type:0,
					suctype:4,
				}
			}
		},

		computed: {
			//日期选择器限制选择
			minOptions: function() {
				let limitTime = this.ruleForm.activeStartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.activeEndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		beforeMount() {

			this.isCopy = this.$route.query.copy ? true : false;
			this.onlyLook = this.$route.query.onlylook ? true : false;
			this.activeState = this.$route.query.activeState ? this.$route.query.activeState : 1;
			if (this.$route.query.id) {
				this.activityId = this.$route.query.id;
				this.getData();

				if (this.isCopy) {
					this.activityId = 0;
				}
			}

		},
		methods: {
			recovery(){
				this.$router.push({path:this.$route.path})
				this.showtype.issuccess = false
				this.ruleForm = {
					name: '',
					activeStartTime: '',
					activeEndTime: '',
					packRuleMoney: '',
					packRulePiece: '',
					ActivityFlag: ''
				}
				this.tableProList = []
				this.paginationList = []
			},
			handleSelectProShow() {
				if ((this.ruleForm.activeStartTime == '' || this.ruleForm.activeStartTime == null) || (this.ruleForm.activeEndTime ==
						'' || this.ruleForm.activeEndTime == null)) {
					this.$message({showClose: true,
						type: 'error',
						message: '请先完善活动时间'
					})
				} else {
					this.selectProShow = true
					this.timeData = {
						StartTime: this.ruleForm.activeStartTime,
						EndTime: this.ruleForm.activeEndTime
					}
				}
			},
			checkPrice(e) {
				e.target.value = e.target.value.match(/\d+(\.\d{0,2})?/) ? e.target.value.match(/\d+(\.\d{0,2})?/)[0] : ''
				if (e.target.value == 0) {
					e.target.value = ''
				}
			},
			// 编辑时初始数据
			async getData() {
				this.loading = true
				const res = await editInit({
					Id: this.activityId
				})
				if (res.IsSuccess) {
					// 复制，去掉开始时间和结束时间
					this.ruleForm = {
						name: res.Result.Name,
						activeStartTime: this.formatDate(res.Result.StartTime),
						activeEndTime: this.formatDate(res.Result.EndTime),
						packRuleMoney: res.Result.BalePrice,
						packRulePiece: res.Result.BaleNum,
						ActivityFlag: res.Result.ActivityFlag,
						IsSuperpositionCoupon:res.Result.IsSuperpositionCoupon
					}
					if (this.isCopy) {
						this.ruleForm = {
							...this.ruleForm,
							activeStartTime:'',
							activeEndTime:'',
						}
					}
					this.tableProList = this.selectedFilterList = res.Result.ProductList
					 
					this.paginationPro()
					
				}
				this.loading = false
			},
			formatDate(date) {
				var list = date.split(' ');
				if (!list.length) return date;
				var year = +list[0].split('-')[0];
				var month = +list[0].split('-')[1] - 1
				var day = +list[0].split('-')[2]
				var hour = +list[1].split(':')[0];
				var minute = +list[1].split(':')[1]
				var second = +list[1].split(':')[2]

				return new Date(year, month, day, hour, minute, second)
			},
			// 取消
			suppilerCansel() {
				this.$router.push({
					path: '/market/packPrice/index'
				});
			},

			// 保存
			saveSupiler(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(!this.tableProList.length){
							this.$common.completeFormInformation('请选择活动商品','is-error-button')
							return false
						}
						this.sureSave();
					} else {
						this.$common.completeFormInformation('请完善活动信息','is-error')
						console.log('error submit!!');
						return false;
					}
				});
			},
			async sureSave() {
				try {
					this.loading = true
					const res = await editSave({
						Id: this.activityId,
						Name: this.ruleForm.name,
						StartTime: this.ruleForm.activeStartTime,
						EndTime: this.ruleForm.activeEndTime,
						BalePrice: this.ruleForm.packRuleMoney,
						BaleNum: this.ruleForm.packRulePiece,
						ActivityFlag: this.ruleForm.ActivityFlag,
						ProductIdList: this.tableProList.map(item => {
							return item.Id
						}),
						IsSuperpositionCoupon:this.ruleForm.IsSuperpositionCoupon
					})

					if (res.IsSuccess) {
						this.$message.success('操作成功')
						if(this.$route.query.copy||!this.$route.query.id){
							this.showtype = {
								issuccess:true,
								id:res.Result.Id,
								// type:this.$route.query.activeType,
								suctype:4,
							}
						}else{
							this.$router.back();
						}
					} else if (res.Result && res.Result.length) {
						this.conflictVisible = true;
						this.conflictList = res.Result;
					}
					this.loading = false
				} catch (e) {
					this.loading = false

				} finally {
					this.loading = false
				}
			},
			handleFilter() {

			},
			handleSizeChange(val) {
				this.page.size = val
				this.paginationPro()
			},
			handleCurrentChange(val) {
				this.page.current = val
				this.paginationPro()
			},
			// 删除指定商品
			deleteSortPro(val,index) {
				this.tableProList=this.tableProList.filter(t=>{
					if(t.Id!=val.Id){
						return t
					}
				})

				this.selectedFilterList=this.selectedFilterList.filter(t=>{
					if(t.Id!=val.Id){
						return t
					}
				})

				if ((this.selectedFilterList.length) % this.page.size == 0 && this.page.current > 1) {
					this.page.current -= 1;
				}


				this.paginationPro()
			},
			//获取选择的商品的列表
			getSelectList(data) {
				this.productSearchKey = ''
				this.tableProList = data;
				// this.selectedFilterList = this.tableProList
				this.selectProShow = false;
				this.handleFilterSelectes()
			},
			handleFilterSelectes(){
				this.page.current = 1
				this.selectedFilterList=[]
				this.tableProList.filter(t=>{
					if((t.Name.indexOf(this.productSearchKey)!=-1)||(t.ProductNo && (t.ProductNo.indexOf(this.productSearchKey)!=-1))){
						this.selectedFilterList.push(t)
					}
				})

				this.paginationPro()
			},
			//商品分页
			paginationPro() {
				this.page.total = this.selectedFilterList.length;
				let start = (this.page.current - 1) * this.page.size
				let end = start + this.page.size
				let pagination = this.selectedFilterList.slice(start, end);
				this.paginationList = pagination
				this.$forceUpdate()
			}
		}
	}
</script>

<style lang="less" scoped>
	.suppierDetail {

		.remarks {
			color: #bbb;
			font-size: 14px;
		}

		.parent-box {
			position: relative;

			.close-bosx {
				position: absolute;
				right: -5px;
				top: -13px;
				font-size: 18px;
			}
		}

		.markRed {
			position: absolute;
			left: -94px;
			top: 5px;
			color: red;
			font-size: 18px;
		}
	}

	.dialog {
		width: 100%;

		::v-deep .el-dialog__header {
			border-bottom: 1px solid #ddd;
		}
	}

	.table-container {
		position: relative;
		overflow: hidden;
	}

	.dialog-name-content {
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 10px;
		}

		.name {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
	}

	.el-form-date-pick {
		display: inline-block;
	}

	.dialog-name-content {
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 10px;
		}

		.name {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
	}
	.footer {
		box-shadow: 13px 1px 6px #999;
		line-height: 80px;
		background-color: #fff;
		position: fixed;
		bottom: 0px;
		width: 100%;
		text-align: center;
		z-index: 999;
	}
</style>
